import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ContactForm from "../components/contact_form"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/social_links"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <section className="page-header">
      <h1>Contact</h1>
    </section>    
    <section className="container flex justify-center items-stretch flex-wrap">
      <div className="service">
        <h2>Openingsuren</h2>
        <ul>
          <li className="my-3"><strong>MA:</strong> gesloten</li>
          <li className="my-3"><strong>DI:</strong> 09:00 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>WO:</strong> 09:00 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>DO:</strong> 09:00 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>VR:</strong> 09:00 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>ZA:</strong> 09:00 - 12:00 | 13:15 - 17:00</li>
          <li className="my-3"><strong>ZO:</strong> gesloten</li>
        </ul>
      </div>
      <div className="service">
        <h2>Contactgegevens</h2>
        <p>
          Optiek De Leersnijder<br/>
          Bredabaan 419 c<br/>
          2990 Wuustwezel
        </p>
        <p>
          <a href="tel:+3236696321" className="hover:text-dl-green">+32 (0)3 669 63 21</a><br/>
          info@optiekdeleersnijder.be
        </p>
        <p>
         <SocialLinks iconsOnly={false} wrapped={true} />
        </p>
      </div>
    </section>
    <section className="section">
      <div className="section-content-right">
        {/* <p className="text-base my-4">Wij maken ons ook sterk door u een goede service aan te bieden.  U kunt steeds rekenen op ons deskundig advies en dankzij onze recente investeringen in ons oogmeetapparatuur kunnen wij tot 25x nauwkeurigere metingen uitvoeren en u zo het best mogelijke zicht garanderen.</p> */}
        <h2 id="afspraak">Afspraak maken</h2>
        <p>Wenst u graag een afspraak te maken? Vul hieronder uw gegevens in en wij nemen zo snel mogelijk contact met u op.</p>
        <div>
          <ContactForm/>
        </div>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/over-ons_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Optiek De Leersnijder"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        {/* <StaticImage
            src="../images/lenzen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        /> */}
      </div>
      <div className="section-bg-left"></div>
    </section>
</Layout>
)

export default ContactPage
